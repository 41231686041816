<template>
  <div class="about">
    <!-- <h1><el-badge :value="'beta'" class="item">Приложение в разработке</el-badge></h1> -->
    <h2>
      <p class="centred">Версия: 1.6.0</p>
      <p class="centred">Сервер: <span v-loading="isDataLoading"> {{serverVer}}</span></p>
    </h2>
  </div>
</template>

<script>
import server from '../api/server-common';

export default {
  data() {
    return {
      serverVer: '',
      isDataLoading: true
    };
  },
  methods: {
    retriveServerInfo() {
      this.isDataLoading = true
      server.retriveServerInfo((serverInfo) => {
            if(serverInfo && serverInfo.data) {
                this.serverVer = serverInfo.data;
            }
            this.isDataLoading = false;
        });
    }
  },
  created() {
    this.retriveServerInfo();
  }
};
</script>

<style scoped>
.about {
  width: 100%;
}
</style>